import styled, { AnyStyledComponent } from 'styled-components'
import { desktopBreakpoint, tabletBreakpoint, wideScreenBreakpoint } from 'react-components'
import CalendarLogo from '../../../assets/images/calendar-icon.svg'
import NoSignLogo from '../../../assets/images/no-sign-icon.svg'
import MoneyLogo from '../../../assets/images/money-icon.svg'
import { H3 } from '@src/components/common/styles'
import Image from 'next/image'

export const Section = styled.div`
    margin-top: 120px;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-top: 134px;
        padding: 0 1.5rem;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin-top: 152px;
    }

    @media only screen and (min-width: ${wideScreenBreakpoint.min}px) {
        margin-top: 184px;
    }
`

export const SectionContent = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex-direction: column;

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        max-width: 1200px;
        margin: 0 auto;
        width: 100%;
        flex-direction: row;
    }
`

export const ContentContainer = styled.div`
    width: 100%;
    text-align: center;
    padding: 16px;
    margin-top: 8px;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-top: 32px;
        padding: 0;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        width: 50%;
        text-align: left;
        margin-top: 0px;
        padding: 0;
    }
`

export const Title = styled(H3).attrs({ as: 'h1' })`
    font: 700 25px/30px ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack99};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        font-size: 40px;
        line-height: 48px;
        margin: 16px 0 8px;
    }
`

export const ListContainer = styled.div`
    margin: 16px auto;
    display: ${props => props.theme.style.display.flex};
    flex-direction: column;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        width: 380px;
        margin: 20px auto;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin: 32px 0 0;
    }
`

export const ListContentContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    margin-bottom: 8px;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-bottom: 16px;
    }
`

export const BoldText = styled.p`
    font-weight: 700;
    display: inline;
    color: ${({ theme }) => theme.colors.tfwBlack99};
`

export const ListIconContainer = styled.div`
    display: inline-block;
    height: 32px;
    width: 32px;
    text-align: center;
    margin-right: 12px;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        height: 40px;
        width: 40px;
    }
`

export const ListTextContainer = styled.div`
    font: 400 ${({ theme }) => theme.typography.font.size.small} / 22px
        ${({ theme }) => theme.typography.font.family.inter};
    color: ${({ theme }) => theme.colors.tfwBlack99};
    text-align: left;
    padding: 8px 0;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        line-height: 1.25rem;
        font-size: 16px;
    }
`

export const ListContent = styled.div`
    display: ${props => props.theme.style.display.flex};
    align-items: center;
`

export const SliderContainer = styled.div`
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-top: 8px;
    }
`

export const RegistrationFormContainer = styled.div`
    scroll-margin-top: 150px;
    margin: 16px 0 0;

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        width: 72%;
        scroll-margin-top: 170px;
        margin: 32px auto 0;
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        width: 50%;
        margin-top: -20px;
    }
`

export const CalendarIcon = styled(CalendarLogo as AnyStyledComponent)`
    width: 100%;
    height: 100%;
`

export const NoSignIcon = styled(NoSignLogo as AnyStyledComponent)`
    width: 100%;
    height: 100%;
`

export const MoneyIcon = styled(MoneyLogo as AnyStyledComponent)`
    width: 100%;
    height: 100%;
`

export const ImageLoader = styled(Image).attrs({
    loading: 'lazy',
})`
    display: block;
`

export const RatingContentContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    align-items: center;
    margin-right: 8px;
`

export const RatingContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    flex-direction: ${props => props.theme.style.flexDirection.column};
    align-items: ${props => props.theme.style.alignItems.center};
    margin-top: 8px;
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin-top: 16px;
    }
`

export const RateContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    align-items: center;
    margin-right: 8px;
    width: 114.14px;
    height: 28px;
`

export const TrustPilotContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    align-items: center;
    width: 81.53px;
    height: 20px;
    margin: 4px 0;
`

export const RatingInnerContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    margin: auto;
    font: 700 ${({ theme }) => theme.typography.font.size.medium} / 24px
        ${({ theme }) => theme.typography.font.family.inter};

    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        margin: 2px auto;
    }
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin-left: 0;
    }
`
export const ListContentInnerContainer = styled.div`
    display: ${props => props.theme.style.display.flex};
    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        margin: 0;
    }
`
